import React, { Component } from 'react'
import { APISolutionStyle } from './APISolution.styles'

class APISolution extends Component {
  render() {
    const { apisolutionProps } = this.props.apisolutionData
    return (
      <APISolutionStyle>
        <div className="container">
          <div className="heading-wrapper">
            <h3>{apisolutionProps.heading}</h3>
          </div>
          <div className="para-row">
            <p>{apisolutionProps.sectionPara}</p>
          </div>
          <div className="solution-listing">
            {apisolutionProps.solutionList.map((listingProp, i) => {
              return (
                <div
                  key={i}
                  className={`single-block ${listingProp.className}`}
                >
                  <div className="icon">
                    <img src={listingProp.icon} alt="" />
                  </div>
                  <div className="content-part">
                    <h4>{listingProp.title}</h4>
                    <ul>
                      {listingProp.lists.map((list, i) => {
                        return <li key={`${i}${i}`}>{list}</li>
                      })}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </APISolutionStyle>
    )
  }
}

export default APISolution
