import styled from 'styled-components'
import { primaryColor, bodyFont, md, lg, sh } from 'config/variables'

export const APISolutionStyle = styled.section`
  .para-row {
    text-align: center;
    p {
      font-size: 24px;
      line-height: 33px;
      color: ${primaryColor};
      ${md(`
        font-size: 20px;
        line-height: 30px;
        `)}
    }
  }
  .solution-listing {
    display: flex;
    border: 1px solid #c3c6d54d;
    margin-top: 55px;
    ${lg(`
      flex-wrap: wrap;
      margin-top: 40px;
      border-right: 0;
    `)}
    ${md(`
      flex-wrap: wrap;
      margin: 0 auto;
      margin-top: 40px;
      max-width: 300px;
      border-right: 1px solid #c3c6d54d;
    `)}
    .single-block {
      width: 25%;
      padding: 31px 22px;
      border-right: 1px solid #c3c6d54d;
      display: flex;
      transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
      ${lg(`
        width: 50%;
        border-bottom: 1px solid #c3c6d54d;
        `)}
      ${md(`
        width: 100%;
        border-right: 0;
        padding: 25px;
        `)}
        ${sh(`
        padding: 22px;
        `)}
      &.pale-grey:hover {
        background: #eef2ff;
      }
      &.pale-green:hover {
        background: #ccf5ba;
      }
      &.very-light-pink:hover {
        background: #fff4ed;
      }
      &.pale:hover {
        background: #fff4dc;
      }
      &:hover {
        .icon {
          transform: translateY(-10px);
        }
        .content-part {
          h4 {
            transform: translateY(-5px);
          }
          ul {
            li {
              transform: translateY(-3px);
            }
          }
        }
      }
      .icon {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
      }
      .content-part {
        width: calc(100% - 65px);
        h4 {
          font-size: 18px;
          line-height: 25px;
          color: ${primaryColor};
          margin-bottom: 11px;
          font-family: ${bodyFont};
          font-weight: 500;
          transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
        }
        ul {
          li {
            font-size: 15px;
            line-height: 22px;
            letter-spacing: -0.15px;
            color: ${primaryColor};
            padding: 0;
            list-style-type: none;
            position: relative;
            padding-left: 15px;
            transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
            &:before {
              content: '';
              width: 3px;
              height: 3px;
              background: ${primaryColor};
              border-radius: 50%;
              top: 10px;
              left: 0;
              position: absolute;
            }
          }
        }
      }
      &:last-child {
        border-right: 0;
        ${lg(`
            border-right: 1px solid #c3c6d54d;
        `)}
        ${md(`
            border-right: 0;
        `)}
      }
    }
  }
`
